import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/style/table.css"
function AgencyRow({ agencyDetails}) {
  return (
    <>
      <div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone No</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {agencyDetails &&
              agencyDetails.map((itx, index) => (
                <tr key={index}>
                  <td>{itx.first_name} {itx.last_name?itx.last_name:''}</td>
                  <td>{itx.phone}</td>
                  <td>{itx.email}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default AgencyRow;
